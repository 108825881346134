
  import { Component, Vue } from 'vue-property-decorator';

  import HipsterInput from '@/components/HipsterInput.vue';
  import HipsterSelector from '@/components/HipsterSelector.vue';
  import HipsterCheckbox from '@/components/HipsterCheckbox.vue';
  import InspiniaGridItem from '@/components/InspiniaGridItem.vue';

  import { SearchTransactionsParams } from '@/types/search';
  import { SimpleTableRowItem, SimpleTableHeaderItem } from '@/types/search';
  import { SEARCH_TRANSACTIONS } from '@/store/transactions';
  import { User } from '@/types/entities';
  import SearchResultsTable from '@/components/SearchResultsTable.vue';
  import { Transaction, PayPalAccount } from '@/types/entities';
  import { RawLocation } from 'vue-router';

  @Component({
    components: { SearchResultsTable, HipsterCheckbox, HipsterInput, HipsterSelector, InspiniaGridItem }
  })
  export default class TransactionsList extends Vue {
    public tableHeader: SimpleTableHeaderItem[] = [
      { class: 'col-lg-1', name: 'ID' },
      { class: 'col-lg-2', name: 'Description' },
      { class: 'col-lg-3', name: 'Sender' },
      { class: 'col-lg-3', name: 'Recipient' },
      { class: 'col-lg-1', name: 'Amount' },
      { class: 'col-lg-1', name: 'Fee' },
      { class: 'col-lg-1', name: 'Status' }
    ];

    public filters: SearchTransactionsParams = {
      per_page: 10,
      page: 1
    };

    public types: object[] = [
      { name: 'All', value: '' },
      { name: 'Withdraw', value: 'Withdraw' },
      { name: 'Added deposit', value: 'Added deposit' },
      { name: 'Bought tip', value: 'Bought tip' }
    ];

    public statuses: object[] = [
      { name: 'All', value: '' },
      { name: 'Pending', value: 'pending' },
      { name: 'Canceled', value: 'canceled' },
      { name: 'Successful', value: 'successful' },
      { name: 'Failed', value: 'failed' }
    ];

    public selectedType = {};
    public selectedStatus = {};

    created() {
      if (this.$route.query.query) {
        this.selectedType = { name: this.$route.query.query, value: this.$route.query.query };
      }

      if (this.$route.query.status) {
        this.selectedStatus = { name: this.$route.query.status, value: this.$route.query.status };
      }

      this.search(this.$route.query as SearchTransactionsParams);
    }

    get transactions() {
      return this.$store.state.transactions.itemsList.data
          .map((transaction: Transaction): SimpleTableRowItem => {
            const sender = this.getAccountEmail(transaction.sender as User);
            const recipient = this.getAccountEmail(transaction.recipient as User);

            return {
              to: `/transactions/${transaction.id}`,
              line: [
                transaction.id as any as string,
                transaction.note as any as string,
                sender,
                recipient,
                transaction.amount as string,
                transaction.fee as string,
                transaction.status as string
              ]
            };
          });
    }

    public search(filter: SearchTransactionsParams) {
      if (!filter.page) {
        filter.page = 1;
      }

      this.filters = Object.assign(this.filters, this.$route.query, filter);

      this.$router.push({ query: this.filters } as RawLocation);

      this.$store.dispatch(SEARCH_TRANSACTIONS, this.filters);
    }

    protected getAccountEmail(user: User): string {
      if (user.pay_pal_account) {
        return (user.pay_pal_account as PayPalAccount).email;
      } else {
        return user.email;
      }
    }
  }
